import React from 'react';
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from '../.';

// import Form from '../components/SignUp/Form';
// import Success from '../components/SignUp/Success';

import { graphql } from "gatsby"
import Img from "gatsby-image"

import styled from 'styled-components';
// import { IoPulseOutline } from "@react-icons/all-files/io5/IoPulseOutline";
// import { FaBookOpen } from "@react-icons/all-files/fa/FaBookOpen";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {  faYoutube } from '@fortawesome/free-brands-svg-icons'

const Image2 = styled(Img)`
    padding: 2.5px;
    border-radius: 25px;
    max-width: 500px;
    height: auto;

    @media (max-width: 1200px) {
        max-width: 100%
    }
`

const Link1 = styled(Link)`
    color: ${props => props.theme.lightBlu};
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
`

const AboutMe = ({data}) => {
    // const [isSubmitted, setIsSubmitted] = useState(false);

    // function submitForm() {
    //     setIsSubmitted(true);
    // }

    return (
        
        <Layout>
            <SEO title="About Me"/>
            <Content.Container>
                <br/>
                <div align="center">
                    <div style={{ maxWidth: `500px`}}>
                        <Image2
                            title="Christina pretending to eat a sunflower."      
                            alt="Christina pretending to eat a sunflower."
                            fluid={data.file.childImageSharp.fluid}
                        />
                    </div>
                </div>
                <br/>
                <Content.Title>About Me</Content.Title>
                <Content.Text>
                    Hey, there! My name is Christina: lover of technology, human languages, 
                    and problem-solving (business!). Currently, I am working on two different ventures. 
                    
                    <br/><br/>(1) I have a 9-to-5 contract position in Big Tech as a Research Program Manager. (It's keeping me quite busy!)
                    
                    <br/><br/>(2) I also have a web design, development, and hosting company called <Link1 to='/AboutDuoLivre'>DuoLivre</Link1>. Not currently accepting new clients.
                </Content.Text>
                {/* <div align='center'><Content.Text>~</Content.Text></div>
                <Content.Text>
                    Want to learn more about the app I am developing? 
                </Content.Text>
                {!isSubmitted ? 
                    <Form submitForm={submitForm}/> :
                    <Success/>
                } */}
                <br/>
            </Content.Container>
        </Layout>
    )
}

export default AboutMe

export const query = graphql`  
  query {    
    file(relativePath: {eq: "Guanica.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
